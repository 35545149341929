<template>
    <b-card>
        <Filters
            :isLoading="isLoadingPerformanceConcepts"
            :filterData="filterData"
            @look-per-filter="filteredSearch"
        />
        <br>

        <div class="d-flex justify-content-end">
            <div class="head-btn">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :to="{ name: 'concept-config-create', params: {hotelId: filterData.hotelId , year: filterData.year}}"
                    title="Agregar"
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>
        </div>
        <br>

        <b-table
            v-if="performanceConcept.length && !isLoadingSomething"
            :items="performanceConcept"
            :fields="tableColumns"
            class="mb-0"
            responsive
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy.sync="isLoadingPerformanceConcepts"
            show-empty
            empty-text="No hay datos para mostrar"
        >
            <template #table-busy>
            <div class="text-center my-2">
                <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>Cargando configuración del reporte de rendimiento
            </div>
            </template>
            <template #cell(status)="row">
                <span class="text-nowrap">
                    <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                    {{ row.item.status ? "Activo" : "Desactivado" }}
                    </b-badge>
                </span>
            </template>
            <template #cell(actions)="row">
                <div>
                    <b-button
                        size="sm"
                        :to="{ name: 'concept-config-edit',  params: { id: row.item.id }}"
                        variant="primary"
                        :class="{ 'd-none': row.item.isLoadingPerformanceConcepts }"
                        class="mr-1"
                        title="Editar"
                    > <feather-icon icon="Edit2Icon" size="12" />
                    </b-button>
                </div>
            </template>
        </b-table>

        <EmptyData
            v-else
            :isLoadingData="isLoadingSomething"
            :dataLength="performanceConcept.length"
            :afterSearch="afterSearch"
            loaderMessage="Cargando configuración del reporte de rendimiento"
        />
    </b-card>
</template>

<script>
import { validYears } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils"
import { fetchHotels } from '@/modules/start/store/actions'
import Filters from '@/modules/fivesClub/components/profit/performanceConcepts/Filters'
import EmptyData from '@/modules/fivesClub/components/profit/EmptyData'
import PaginationTable from '@/modules/fivesClub/components/profit/costs/PaginationTable'
import Ripple from 'vue-ripple-directive'

export default {
    mixins: [utils],
    directives: { Ripple, },
    components:{
        PaginationTable,
        Filters,
        EmptyData
    },
    data() {
        return {
            perPage: 100,
            currentPage: 1,
            perPageOptions: [50, 100, 150, 200, 250, 500, 1000],
            sortBy: 'name',
            isSortDirDesc: true,
            refListTable : null,
            statusFilter: null,
            tableColumns: [
                { key: "year", label: "Año", class: "text-center" },
                { key: "Hotel", label: "Hotel", class: "text-center" },
                { key: "conceptEsp", label: "Nombre", class: "text-center" },
                { key: "conceptEng", label: "Nombre en inglés", class: "text-center" },
                { key: "value", label: "Valor", class: "text-center", formatter: (value, key, item) => { return this.formatValue(item) } },
                { key: "status", label:"Status", class: "text-center"},
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
            filterData: {
                hotelId: null,
                year: new Date().getFullYear(),
            },
            isLoadingPerformanceConcepts: false,
            isLoadingInitialData: false,
            afterSearch: false
        };
    },
    async mounted() {
        await this.init()
    },
    computed: {
        ...mapState('fivesClubProfit',['performanceConcept']),
        ...mapState('start',['hotels']),
        isLoadingSomething(){
            return this.isLoadingPerformanceConcepts || this.isLoadingInitialData
        },
        listyears(){
            return validYears()
        },
    },
    methods: {
        ...mapMutations('start',['setHotels']),
        ...mapActions('fivesClubProfit', ['fetchPerformanceConfigutation']),
        ...mapMutations('fivesClubProfit',['setPerformanceConcepts']),
        async init(){
            this.isLoadingInitialData = true

            if( this.hotels.length === 0 ){
                const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }
                const hotels = await fetchHotels(payloadHotels)
                this.setHotels(hotels)
            }

            if (this.$route.params.reload) {
                this.filterData = {
                    hotelId: this.$route.params.hotelId,
                    year: this.$route.params.year,
                },
                await this.filteredSearch(this.filterData)
            }

            this.isLoadingInitialData = false

        },
        formatValue(item){
            // 1 - moneda, 2 - porcentaje, 4 - numerico
            if (item.conceptType == 1) return this.formatDecimal(item.value,true)
            if (item.conceptType == 2) return `${this.formatDecimal(item.value)}%`
            return this.formatDecimal(item.value)

        },
        formatDecimal(value, includeSymbol = false) {
            const numericValue = parseFloat(value);
            if (!isNaN(numericValue) && isFinite(numericValue)) {
                return this.toDecimal(numericValue, includeSymbol);
            } else {
                return value;
            }
        },

        toDecimal(number, includeSymbol) {
            if (typeof number !== 'number' || isNaN(number) || !isFinite(number)) {
                throw new Error('Invalid number');
            }

            const formattedValue = number.toLocaleString('es-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            const formattedWithSymbol = includeSymbol ? `$${formattedValue}` : formattedValue;
            return formattedWithSymbol;
        },

        async filteredSearch(payload){
            this.isLoadingPerformanceConcepts = true
            const result = await this.fetchPerformanceConfigutation(payload)
            this.setPerformanceConcepts(result)
            this.afterSearch = true
            this.isLoadingPerformanceConcepts = false
        }
    },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
    .mb-0.table-responsive {
        max-height: 369px;
    }
    .table-responsive th {
        padding: 10px 10px 10px 10px !important;
        font-size: 10px;
    }
    .table-responsive th div {
        font-size: 10px;
    }
    .table-responsive td {
        padding: 5px !important;
        font-size: 11px;
    }
    .buttonInfo button {
        padding: 9px;
    }

    .buttonInfo {
        display: table-cell;
    }
}

.swal-button-yes {
    background-color: #3085d6;
    color: #fff;
    margin-right: 10px;  /* Ajusta la separación a tu preferencia */
}

/* Estilo para el botón de cancelación */
.swal-button-cancel {
    background-color: #d33;
    color: #fff;
}
</style>
